import React from "react"
import { Link } from "gatsby";

import Layout from "../components/layout"
import SEO from "../components/seo"

const Cookies = ({ pageContext }) => (
    <Layout {...pageContext}>
        <SEO 
            lang={pageContext.lang}
            path={pageContext.pageUrl}
            title={pageContext.title}
            noindex={true}
        />
        <div id="header" className="for-properties">
            <div className="section-content">
                <div className="big-text">
                    <h1 className="t-left f-large normal">{pageContext.title}</h1>
                </div>
            </div>
        </div>
        <div id="description">
            <div className="section-content">
                <div className="row">
                    <div className="col-md-8">
                        <div className="brief no-margin t-left link-underline">            
                            <p>En cumplimiento con el artículo 22 de la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico (LSSI), en relación con el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, General de Protección de Datos (RGPD) y la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos y garantía de los derechos digitales (LOPDGDD), Pat Swiss Invest SL pone a disposición de los usuarios la Política de recogida y tratamiento de cookies del sitio Web.</p>
                            <h2>Qué son las Cookies</h2>
                            <p>Una cookie es un fichero que se descarga en tu ordenador al entrar a determinadas páginas web. Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar información sobre tus hábitos de navegación y &mdash;dependiendo de la información que contengan y de la forma en que utilices tu equipo&mdash; pueden utilizarse para identificarte.</p>
                            <h2>Cookies utilizadas en el sitio Web</h2>
                            <p>A continuación se clasifican las cookies utilizadas por el sitio Web <Link to="/">patricia.properties</Link>:</p>
                            <p><b>Según la entidad que las gestiona</b></p>
                            <ul>
                                <li><b>Cookies propias:</b> Son aquellas enviadas y gestionadas directamente por Pat Swiss Invest SL.</li>
                                <li><b>Cookies de terceros:</b> Son aquellas que se envían al usuario desde un dominio ajeno a Pat Swiss Invest SL.</li>
                            </ul>
                            <p><b>Según su finalidad</b></p>
                            <ul>
                                <li><b>Cookies técnicas:</b> Son aquellas que permiten a los usuarios registrados navegar a través del sitio Web, del área restringida y a utilizar sus diferentes funciones, como por ejemplo, el buscador.</li>
                                <li><b>Cookies de personalización:</b> Son aquellas que permiten a los usuarios acceder al Servicio con algunas características de carácter general predefinidas en función de una serie de criterios establecidos por el usuario como, por ejemplo, el idioma o el tipo de navegador a través del cual se conecta a este sitio Web.</li>
                                <li><b>Cookies de análisis o medición:</b> Son aquellas que, bien tratadas por el sitio Web o por terceros, permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del sitio Web. Para ello se analiza la navegación que realizas en este sitio Web con el fin de mejorarlo.</li>
                                <li><b>Cookies de publicidad comportamental:</b> Son aquellas que, bien tratadas por el sitio Web o por terceros, almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada de sus hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar publicidad en función del mismo.</li>
                                <li><b>Cookies sociales:</b> Son establecidas por las plataformas de redes sociales para permitir a los usuarios compartir contenido con sus amigos y redes.</li>
                            </ul>
                            <p><b>Según su duración</b></p>
                            <ul>
                                <li><b>Cookies de sesión:</b> Son aquellas diseñadas para recabar y almacenar datos mientras el usuario accede al sitio Web.</li>
                                <li><b>Cookies persistentes:</b> Son aquellas en las que los datos siguen almacenados en el terminal del usuario y pueden ser accedidos y tratados durante un período definido por el responsable de la cookie.</li>		
                            </ul>
                            <h2>Listado de cookies utilizadas</h2>
                            <p>Este sitio Web puede instalar las <a href="#" onClick={(e) => {
                                e.preventDefault();
                                window.cookiehub.openSettings();
                            }}>siguientes cookies</a> (una vez clicado el enlace, puedes consultarlas en la pestaña "Declaración de cookies").</p>
                            <h2>Aceptación o rechazo de la instalación de cookies</h2>
                            <p>Pat Swiss Invest SL muestra información sobre su Política de cookies en el menú del pie de página y en el banner de cookies accesible en todas las páginas del Sitio Web. El banner de cookies muestra información esencial sobre el tratamiento de datos y permite al usuario realizar las siguientes acciones:</p>
                            <ul>
                                <li><b>Aceptar la instalación de cookies</b>.</li>
                                <li><b>Rechazar la instalación de cookies</b> o retirar el consentimiento previamente otorgado.</li>
                                <li><b>Obtener información adicional</b> sobre qué son las cookies, conocer la Política de Cookies y modificar la configuración para aceptar o rechazar las cookies en cualquier momento.</li>
                            </ul>
                            <h2>Eliminación de cookies</h2>
                            <p>Puedes aceptar, bloquear o eliminar las cookies instaladas en tu equipo mediante la configuración de las opciones del navegador, pero parte del sitio no funcionará correctamente o su calidad puede verse afectada.</p>
                            <p>En los siguientes enlaces encontrarás instrucciones para habilitar o deshabilitar las cookies en los navegadores más comunes.</p>	
                            <ul>
                                <li><b>Firefox</b>  <a rel="nofollow" target="_blank" rel="noopener noreferrer nofollow" href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</a></li>
                                <li><b>Google Chrome</b> <a target="_blank" rel="noopener noreferrer nofollow" href="https://support.google.com/chrome/answer/95647?hl=es">https://support.google.com/chrome/answer/95647?hl=es</a></li>
                                <li><b>Internet Explorer</b> <a target="_blank" rel="noopener noreferrer nofollow" href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies#ie="ie-10"</a></li>
                                <li><b>Microsoft Edge</b> <a target="_blank" rel="noopener noreferrer nofollow" href="https://support.microsoft.com/es-es/help/4468242/microsoft-edge-browsing-data-and-privacy">https://support.microsoft.com/es-es/help/4468242/microsoft-edge-browsing-data-and-privacy</a></li>
                                <li><b>Safari</b> <a rel="nofollow" target="_blank" rel="noopener noreferrer nofollow" href="http://support.apple.com/kb/HT1677?viewlocale=es_ES">http://support.apple.com/kb/HT1677?viewlocale=es_ES</a></li>
                            </ul>
                            <h2>Tratamiento de Datos Personales</h2>
                            <p>Pat Swiss Invest SL es el Responsable del tratamiento de los datos personales del usuario. Puedes consultar toda la información relativa al tratamiento de datos personales que recoge el Titular en la página de <Link to="/es/politica-de-privacidad/">Política de Privacidad</Link>.</p>
                            <h2>Contacto</h2>
                            <p>En caso de que tengas cualquier duda acerca de esta Política de Cookies o quieras realizar cualquier comentario sobre este sitio Web, puedes enviar un mensaje de correo electrónico a la dirección <a href="mailto:legal@patricia.properties">legal@patricia.properties</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
);

export default Cookies;
